body {
  overflow-x: hidden;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

input, button {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

nav {
  padding: 0;
  margin: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
